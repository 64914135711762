import React from 'react'
import './Dimmer.css'

// TODO: Add onClose
export default class Dimmer extends React.Component {
    
    render() {
        if (this.props.open) {
            return (
                <div id="dimmer" style={this.props.style}>{this.props.loader ? <Loader /> : this.props.children}</div>
            )
        }
        return null;
    }
}

const Loader = () => (
    <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
    </div>
)