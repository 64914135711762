import React from 'react'
import { Route, Switch } from 'react-router'
import Loadable from 'react-loadable'
import PrivateRoute from './PrivateRoute'
import * as urls from './helpers/urls'


import './root.css'
import ProjectEditExperimental from './components/Private/ProjectEditExperimental';


// Every container can have its own loader eg
// const Loading = () => <div>Laddar...</div>

export const DefaultLoader = (props) => {
    if (props.error) return <div>Error!</div>
    else if (props.timedOut) return <div>Timeout <button onClick={props.retry}>Ladda om (todo)</button></div>
    else if (props.pastDelay) return <div>Laddar...</div>
    else return null;
}


const ProjectsAsync = Loadable({
    loader: () => import('./containers/ProjectsContainer'),
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
})

const ProjectAsync = Loadable.Map({
    loader: {
        ProjectContainer: () => import('./containers/ProjectContainer'),
        copy: () => import('./helpers/translations').then(x => x.projectDictionary)
    },
    render(loaded, props) {
        let Container = loaded.ProjectContainer.default;
        let copy = loaded.copy;
        return <Container {...props} copy={copy} />
    },
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
})

const LoginAsync = Loadable.Map({
    loader: {
        LoginContainer: () => import('./containers/LoginContainer'),
        copyData: () => import('./helpers/translations').then(x => x.loginDictionary),
        errors: () => import('./helpers/translations').then(x => x.errors)
    },
    render(loaded, props) {
        let LoginContainer = loaded.LoginContainer.default;
        let dictionary = Object.assign({}, loaded.copyData, loaded.errors);
        const lprops = Object.assign({}, props, { dictionary })
        return <LoginContainer {...lprops} />
    },
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
})

const MembersAsync = Loadable.Map({
    loader: {
        Component: () => import('./containers/MembersContainer'),
        Members: () => import('./helpers/translations').then(x => x.Members),
        Messages: () => import('./helpers/translations').then(x => x.Messages)
    },
    render(loaded, props) {
        // let copy = loaded.copyData;
        const leprops = {}
        let dictionary = {}
        for (let o in loaded) {
            if (o === "Component") continue;
            Object.assign(dictionary, loaded[o])
        }
        Object.assign(leprops, props, { dictionary })

        return <loaded.Component.default {...leprops} />
    },
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
});

const MessagesAsync = Loadable.Map({
    loader: {
        Component: () => import('./components/Private/Messages'),
        Messages: () => import('./helpers/translations').then(x => x.Messages)
    },
    render(loaded, props) {
        // let copy = loaded.copyData;
        const mProps = Object.assign({}, props, { dictionary: loaded.Messages })
        return <loaded.Component.default {...mProps} />
    },
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
});

const MessageAsync = Loadable.Map({
    loader: {
        Component: () => import('./components/Private/Messages/Message'),
        Messages: () => import('./helpers/translations').then(x => x.Messages)
    },
    render(loaded, props) {
        const mprops = Object.assign({}, props, { dictionary: loaded.Messages })
        return <loaded.Component.default {...mprops} />
    },
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
})

const ProjectCreateAsync = Loadable.Map({
    loader: {
        Component: () => import('./containers/ProjectsContainer'),
        ProjectEdit: () => import('./helpers/translations').then(x => x.ProjectEdit),
        MyUser: () => import('./helpers/translations').then(x => x.MyUser),
    },
    render(loaded, props) {
        const pprops = Object.assign({}, props, { dictionary: { ...loaded.ProjectEdit, ...loaded.MyUser } });
        return <loaded.Component.default {...pprops} />
    },
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
})

const RegisterByTokenAsync = Loadable.Map({
    loader: {
        Component: () => import('./components/Public/RegisterByToken'),
        loginform: () => import('./helpers/translations').then(x => x.loginForm),
        errors: () => import('./helpers/translations').then(x => x.errors),
    },
    render(loaded, props) {
        const dictionary = {}
        Object.assign(dictionary, loaded.loginform, loaded.errors);
        return <loaded.Component.default {...props} dictionary={dictionary} />
    },
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
})

const WebFront = Loadable.Map({
    loader: {
        Component: () => import('./components/Public/FrontWeb')
    },
    render(loaded, props) {
        return <loaded.Component.default {...props} />
    },
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
})

const MyUserAsync = Loadable.Map({
    loader: {
        Component: () => import('./containers/MyUserContainer'),
        languageTranslation: () => import('./helpers/translations').then(x => x.languageTranslation),
        MyUser: () => import('./helpers/translations').then(x => x.MyUser),
    },
    render(loaded, props) {
        // Dunny why translations lol...
        const translations = Object.assign({}, loaded.languageTranslation)
        const mprops = Object.assign({}, props, { dictionary: loaded.MyUser })
        return <loaded.Component.default {...mprops} translations={translations} />
    },
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
});

const ResetPassword = Loadable.Map({
    loader: {
        Component: () => import('./components/Public/ResetPassword'),
    },
    render(loaded, props) {
        return <loaded.Component.default {...props} />
    },
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
})

const NotificationListAsync = Loadable.Map({
    loader: {
        Component: () => import('./components/Private/NotificationList'),
        Notifications: () => import('./helpers/translations').then(x => x.Notifications)
    },
    render(loaded, props) {
        const nprops = Object.assign({}, props, { dictionary: loaded.Notifications })
        return <loaded.Component.default {...nprops} />
    },
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
})




class Routes extends React.Component {

   
    render() {
        return (
            <Switch >
                <Route exact path={urls.LOGIN} component={LoginAsync} />
                <Route exact path={urls.ABOUT} component={WebFront} />
                <Route exact path={urls.WHY_BB} component={WebFront} />
                <Route exact path={urls.ORDER} component={WebFront} />
                <Route exact path={"/register/:token"} component={RegisterByTokenAsync} />
                <Route exact path="/" component={WebFront} />
                <Route exact path="/resetpassword/:jwt" component={ResetPassword} />
                {/* <Route exact path="/skojaren" component={ProjectEditExperimental} /> */}

                <PrivateRoute exact path="/projects" component={ProjectsAsync} />
                <PrivateRoute exact path="/projects/:projectId/:edit" component={ProjectAsync} />
                <PrivateRoute exact path="/messages" component={MessagesAsync} />
                <PrivateRoute exact path="/messages/:convoId" component={MessageAsync} />
                <PrivateRoute exact path="/project/:projectId" component={ProjectAsync} />
                <PrivateRoute exact path="/projects/create" component={ProjectCreateAsync} />
                {/* <PrivateRoute exact path ="/projects/:id?/edit" component={ProjectsAsync} /> */}
                <PrivateRoute exact path="/project/:projectId/members" component={MembersAsync} />
                <PrivateRoute exact path="/myUser" component={MyUserAsync} />
                <PrivateRoute exact path="/notifications" component={NotificationListAsync} />
                
            </Switch>
        )
    }
}

export default Routes;