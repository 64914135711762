import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ProjectDetailsForm from './ProjectDetailsForm';
import Dimmer from '../../Public/Dimmer'
import UploadImageForm from '../MyUser/UploadImageForm'

class Container extends React.Component {
    state = {
        showUpload: false,
        project: {}
    }

    uploadImage = (files) => {
        files.length && this.onProjectDetailChange({value:files[0], name:"projectImage" })
    }

    toggleUpload = () => this.setState({ showUpload: !this.state.showUpload })

    onProjectDetailChange = ({ value, name }) => {
        const project = Object.assign({}, this.state.project, { [name]: value })
        this.setState({ project })
    }

    render() {
        const projectDetailsFormProps = Object.assign({}, {
            language: this.props.language,
            errors: [],
            project: this.state.project,
            onChange: this.onProjectDetailChange,
            showUploadAvatar:this.toggleUpload
        });
        return (
            <div>
                <ProjectDetailsForm {...projectDetailsFormProps} />
                <Dimmer open={this.state.showUpload}>
                    <UploadImageForm cancel={this.toggleUpload} uploadImage={this.uploadImage} {...this.props} />
                </Dimmer>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.languages.selectedLanguage,

    }
}

export default withRouter(connect(mapStateToProps)(Container));