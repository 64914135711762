export const DESC_CAT_CREATE_PENDING = "file.DESC_CAT_CREATE_PENDING"
export const DESC_CAT_CREATE_FAILED = "file.DESC_CAT_CREATE_FAILED"
export const DESC_CAT_CREATE_SUCCESS = "file.DESC_CAT_CREATE_SUCCESS"

export const FILE_UPLOAD_PENDING = 'file.FILE_UPLOAD_PENDING'
export const FILE_UPLOAD_FAILED = 'file.FILE_UPLOAD_PENDING'
export const FILE_UPLOAD_SUCCESS = 'file.FILE_UPLOAD_SUCCESS'
export const FILE_UPLOAD_SET_COUNT = 'file.FILE_UPLOAD_SET_COUNT'

export const GET_FILECATEGORIES_SUCCESS = 'file.GET_FILECATEGORIES_SUCCESS'

export const GET_FILES_PENDING = 'file.GET_FILES_PENDING'
export const GET_FILES_SUCCESS = 'file.GET_FILES_SUCCESS'

export const FILE_DELETE_REQUESTING = 'projects.FILE_DELETE_REQUESTING'
export const FILE_DELETE_SUCCESS = 'projects.FILE_DELETE_SUCCESS'
export const FILE_DELETE_FAILED = 'projects.FILE_DELETE_FAILED'