import Immutable from 'seamless-immutable'
import * as languageTypes from './actionTypes'

import { languages } from '../../helpers/translations'

const initState = Immutable({
    selectedLanguage: languages.Swedish
})

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case languageTypes.CHANGING_LANGUAGE:
            return state.merge({
                selectedLanguage: action.payload
            })
        default:
            return state;
    }
}