import * as userTypes from './actionTypes';
import Immutable from 'seamless-immutable'

const initState = Immutable({
    email: "",
    password: "",
    access_token: "",
    isLoggingIn: false,
    firstname: "",
    lastname: "",
    id: "",
    title: "",
    error: "",
    isRequesting: false, // Actually try use this for all requests regarding user, if god wills and there is time to refactor, replace "isLoggingIn"
    members: [],
    userPrivileges: [],
    isForgotPasswordMailSent: false,
    forgotPasswordError: "",
    isNotificationMail: false,
    notifications: [],
    fetchStatusPaused: false
});

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case userTypes.PAUSE_FETCHSTATUS:
            return state.merge({
                fetchStatusPaused: true
            });
        case userTypes.RESUME_FETCHSTATUS:
            return state.merge({
                fetchStatusPaused: false
            })
        
        case userTypes.LOGIN_PENDING:
            return state.merge({
                isLoggingIn: true,
                isRequesting: true,
                error: ""
            });
        case userTypes.UPDATE_USER_PENDING:
            return state.merge({
                isRequesting: true
            })
        
        case userTypes.LOGIN_FAILED:
            return state.merge({
                isLoggingIn: false,
                error: action.payload.data && action.payload.data.message
            })
        case userTypes.LOGIN_SUCCESS:
        case userTypes.CREATE_USER_SUCCESS:
            return state.merge({
                ...action.payload,
                error: "",
                isRequesting: false
            })
        case userTypes.CREATE_USER_ERROR:
            return state.merge({
                error: action.payload
            })
        case userTypes.CLEAR_ERROR:
            return state.merge({
                error: "",
                forgotPasswordError: ""
            })
        case userTypes.LOGOUT:
            return initState;
        case userTypes.GET_NOTIFICATIONS_SUCCESS:
            return state.merge({
                ...action.payload
            });
        case userTypes.USER_REQUESTING:
            return state.merge({
                isRequesting: true
            })

        case userTypes.INVITATION_SUCCESS:
            return state.merge({
                isRequesting: false
            })
        case userTypes.INVITATION_FAILED:
            return state.merge({
                isRequesting: false,
                error: "placeholder"
            })
        case userTypes.GET_USERS_PROJECT_SUCCESS:
            return state.merge({
                members: action.payload,
                isRequesting: false
            })
        case userTypes.SENT_FORGOT_PASSWORD_SUCCESS:
            return state.merge({
                isForgotPasswordMailSent: true,
                isRequesting: false
            })
        case userTypes.SENT_FORGOT_PASSWORD_FAILED:
            return state.merge({
                isRequesting: false,
                isForgotPasswordMailSent: false,
                forgotPasswordError: action.payload
            })
        case userTypes.RESET_MEMBERS:
            return state.merge({
                members: []
            })
        default:
            return state;
    }
}