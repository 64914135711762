import React, { Component } from 'react'
import Trash from '../../../assets/Trash.png'
import Download from '../../../assets/Download.png'
import Download_Cloud from '../../../assets/Download_Cloud.png'
import Home_White from '../../../assets/Home_white.png'
import Folder_White from '../../../assets/Folder_white.png'
import Users_White from '../../../assets/Users_white.png'
import Edit from '../../../assets/edit.png'
import Edit_White from '../../../assets/edit_white.png';
import Bell_Black from '../../../assets/Bell_black.png';
import Mail_Black from '../../../assets/Mail_black.png';
import Paperclip_Black from '../../../assets/PaperClip_black.png';
import User_Black from '../../../assets/User.png';
import FolderOpen from '../../../assets/FolderOpen.png';
import Folder from '../../../assets/Folder.png';
import Sync from '../../../assets/Sync.png';
import Users from '../../../assets/Users.png';
import Bell from '../../../assets/Bell.png';
import SpeachBubble from '../../../assets/SpeachBubble.png';
import SpeachBubbles from '../../../assets/SpeachBubbles.png';
import Information from '../../../assets/Information.png';
import FlowChart from '../../../assets/Flowchart.png';
import Power_Black from '../../../assets/Power_Black.svg';
import Preferences_Black from '../../../assets/Preferences_Black.svg';
import Upload_Cloud from '../../../assets/UploadCloud.svg'
import Announcement from '../../../assets/Announcement.svg';
import Search from '../../../assets/Search.svg';
import Protection from '../../../assets/Active Protection.svg';
import OpenFolder from '../../../assets/Open Folder.svg';
import FileIcon from '../../../assets/File_1.svg'


export default class Icon extends Component {

    renderMailBlack = () => <img  src={Mail_Black} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="mail black icon" />
    renderBellBlack = () => <img  src={Bell_Black} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="bell black icon" />
    renderTrash = () => <img  src={Trash} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="trash icon" />
    renderDownload = () => <img  src={Download} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="download icon" />
    renderDownloadCloud = () => <img  src={Download_Cloud} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="download cloud icon" />
    renderUploadCloud = () => <img  src={Upload_Cloud} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="upload cloud icon" />
    renderHomeWhite = () => <img  src={Home_White} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="home white icon" />
    renderFolderWhite = () => <img  src={Folder_White} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="folder white icon" />
    renderUsersWhite = () => <img  src={Users_White} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="user white icon" />
    renderEdit = () => <img  src={Edit} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="edit icon" />
    renderEditWhite = () => <img  src={Edit_White} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="edit white icon" />
    renderPaperclip = () => <img  src={Paperclip_Black} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="paperclip icon" />
    renderUser = () => <img  src={User_Black} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="user icon" />
    renderFolderOpen = () => <img  src={FolderOpen} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="folder open icon" />
    renderSync = () => <img  src={Sync} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="sync icon" />
    renderUsers = () => <img  src={Users} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="users icon" />
    renderBell = () => <img  src={Bell} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="bell icon" />
    renderSpeachBubble = () => <img  src={SpeachBubble} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="speech bubble icon" />
    renderSpeachBubbles = () => <img  src={SpeachBubbles} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="speech bubbles icon" />
    renderFolder = () => <img  src={Folder} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="folder icon" />
    renderInformation = () => <img  src={Information} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="information icon" />
    renderFlowChart = () => <img  src={FlowChart} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="flowchart icon" />
    renderPowerBlack = () => <img  src={Power_Black} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="Power Black icon" />
    renderPreferencesBlack = () => <img  src={Preferences_Black} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="Preferences Black icon" />
    renderAnnouncement = () => <img  src={Announcement} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="upload cloud icon" />
    renderSearch = () => <img  src={Search} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="upload cloud icon" />
    renderProtection = () => <img  src={Protection} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="upload cloud icon" />
    renderOpenFolder = () => <img  src={OpenFolder} height={this.props.height ? this.props.height : "30"} onClick={this.props.onClick} style={this.props.style} alt="upload cloud icon" />
    renderAdd = () => <svg xmlns="http://www.w3.org/2000/svg" width="38.518" height="38.519" viewBox="0 0 38.518 38.519" ><path className="a" style={{ fill: "#172029" }} d="M717.259,1923.518a19.259,19.259,0,1,1,19.259-19.259A19.281,19.281,0,0,1,717.259,1923.518Zm0-36.844a17.584,17.584,0,1,0,17.584,17.584A17.6,17.6,0,0,0,717.259,1886.675Z" transform="translate(-698 -1885)" /><g transform="translate(8.374 18.422)"><path className="a" style={{ fill: "#172029" }} d="M723.934,1897.675h-20.1a.837.837,0,0,1,0-1.675h20.1a.837.837,0,0,1,0,1.675Z" transform="translate(-703 -1896)" /></g><g transform="translate(18.422 8.374)"><path className="a" style={{ fill: "#172029" }} d="M709.837,1911.771a.838.838,0,0,1-.837-.837v-20.1a.837.837,0,1,1,1.675,0v20.1A.838.838,0,0,1,709.837,1911.771Z" transform="translate(-709 -1890)" /></g></svg>
    renderMousePointerIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 28.47 40.191">
            <path className="a" style={{ fill: "#172029" }} d="M653.837,2930.192a.836.836,0,0,1-.837-.838v-38.518a.837.837,0,0,1,.518-.774.847.847,0,0,1,.913.181l26.8,26.8a.838.838,0,0,1-.593,1.43H664.269l-9.8,11.43A.838.838,0,0,1,653.837,2930.192Zm.837-37.334v34.233l8.575-10a.835.835,0,0,1,.636-.293h14.726Z" transform="translate(-653 -2890.001)" />
        </svg>
    );
    renderClose = () => (
        <svg onClick={this.props.onClick} xmlns="http://www.w3.org/2000/svg" style={this.props.style} width={this.props.height ? this.props.height : "30.146"} height={this.props.height ? this.props.height : "30.146"} viewBox="0 0 30.146 30.146">
            <path style={{ fill: "#172029" }} d="M969.807,2252.645a.841.841,0,0,1-.593-.245l-28.47-28.47a.838.838,0,0,1,1.186-1.186l28.47,28.47a.838.838,0,0,1-.593,1.43Z" transform="translate(-940.5 -2222.5)" />
            <path style={{ fill: "#172029" }} d="M941.338,2252.645a.838.838,0,0,1-.593-1.43l28.47-28.47a.838.838,0,0,1,1.186,1.186l-28.47,28.47A.841.841,0,0,1,941.338,2252.645Z" transform="translate(-940.5 -2222.5)" />
        </svg>
    )

    renderFile = () => <img alt="File" src={FileIcon} height={this.props.height || "30px"} {...this.props} />

    render() {
        switch (this.props.name.toLowerCase()) {
            case "mouse pointer":
                return this.renderMousePointerIcon();
            case "trash":
                return this.renderTrash();
            case "download":
                return this.renderDownload();
            case "download cloud":
                return this.renderDownloadCloud();
            case "upload cloud":
                return this.renderUploadCloud();
            case "home white":
                return this.renderHomeWhite();
            case "folder white":
                return this.renderFolderWhite();
            case "users white":
                return this.renderUsersWhite();
            case "edit":
                return this.renderEdit();
            case "edit white":
                return this.renderEditWhite();
            case "mail black":
                return this.renderMailBlack();
            case "bell black":
                return this.renderBellBlack();
            case "paperclip":
                return this.renderPaperclip();
            case "user":
                return this.renderUser();
            case "folder open":
                return this.renderFolderOpen();
            case "sync":
                return this.renderSync();
            case "users":
                return this.renderUsers();
            case "bell":
                return this.renderBell();
            case "speach bubble":
                return this.renderSpeachBubble();
            case "speach bubbles":
                return this.renderSpeachBubbles();
            case "folder":
                return this.renderFolder();
            case "information":
                return this.renderInformation();
            case "flowchart":
                return this.renderFlowChart();
            case "add":
                return this.renderAdd()
            case "power black":
                return this.renderPowerBlack();
            case "preferences black":
                return this.renderPreferencesBlack();
            case "close":
                return this.renderClose();
            case "announcement":
                return this.renderAnnouncement();
            case "search":
                return this.renderSearch();
            case "protection":
                return this.renderProtection();
            case "file":
                return this.renderFile();
            default:
                return (<div></div>)
        }
    }
}