import Immutable from 'seamless-immutable'
import * as qaTypes from './actionTypes'

const initState = Immutable({
    qaList: [],
    isQaRequesting: false,
    isQaCreating: false
})

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case qaTypes.QA_REQUESTING:
            return state.merge({
                isQaRequesting: true
            })
        case qaTypes.QA_LIST_GET_SUCCESS:
            return state.merge({
                qaList: action.payload,
                isQaRequesting: false
            })
        case qaTypes.QA_CREATE_REQUESTING:
            return state.merge({
                isQaCreating: true
            })
        case qaTypes.QA_CREATE_SUCCESS:
            return state.merge({
                isQaCreating: false
            })
        default:
            return state;
    }
}