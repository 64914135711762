import React from 'react'
import FormField from '../../Public/FormField'
import Button from '../../Public/Button'

export default class ProjectDetailsForm extends React.Component {
    state = {
        dictionary: undefined
    }

    componentDidMount() {
        Promise.all([
            import('../../../helpers/translations').then(x => x.ProjectEdit),
            import('../../../helpers/translations').then(x => x.projectDictionary),
        ]).then(res => {
            let dictionary = {}
            res.forEach(e => Object.assign(dictionary, e))
            this.setState({ dictionary })
        })
    }

    isInvalid = (field) => {
        return this.props.errors.some(x => x === field)
    }

    onInputChange = e => {
        e && e.preventDefault();
        this.props.onChange && this.props.onChange({value:e.target.value, name:e.target.name })
    }

    showUpload = () => {
        this.props.showUploadAvatar && this.props.showUploadAvatar();
    }

    render() {
        
        const { dictionary } = this.state;
        if(!dictionary) return <div>...</div>

        const { language, projectNameError, edit } = this.props
        return (
            <div>
                SKojaren lol
                <h1>{edit ? dictionary.EDIT_PROJECT[language] : dictionary.CREATE_PROJECT[language]}</h1>
                <h2>{this.props.name}</h2>

                <div className="formFields">

                    <FormField dark label={dictionary.NAME[language]} invalid={this.isInvalid("uniqueName") || projectNameError} errortext={projectNameError} >
                        <input name="uniqueName" placeholder={dictionary.NAME[language]} value={this.props.uniqueName} onChange={this.onInputChange} />
                    </FormField>
                    <div className="twoColContainer">
                        <div className="col-left">
                            <FormField dark label={dictionary.START_DATE[language]} invalid={this.isInvalid("startDate")}>
                                <input type="date" max="2999-12-31" name="startDate" placeholder={dictionary.DATE[language]} value={this.props.startDate} onChange={this.onInputChange} />
                            </FormField>
                        </div>
                        <div className="col-right">
                            <FormField dark label={dictionary.ESTIMATED_END[language]} invalid={this.isInvalid("endDate")}>
                                <input type="date" max="2999-12-31" name="endDate" placeholder={dictionary.DATE[language]} value={this.props.endDate} onChange={this.onInputChange} />
                            </FormField>
                        </div>
                    </div>


                    <div className="inverted-container">
                        <div className="twoColContainer">
                            <div className="col-left">
                                <FormField label={dictionary.ACCESS[language]} invalid={this.isInvalid("accessDate")}>
                                    <input type="date" max="2999-12-31" name="accessDate" placeholder={dictionary.DATE[language]} value={this.props.accessDate} onChange={this.onInputChange} />
                                </FormField>
                            </div>
                            <div className="col-right">
                                <FormField label={dictionary.SURFACE[language]} invalid={this.isInvalid("area")}>
                                    <input type="number" name="area" placeholder={dictionary.SURFACE[language]} value={this.props.area || undefined} onChange={this.onInputChange} />
                                </FormField>
                            </div>
                        </div>
                        <div className="twoColContainer">
                            <div className="col-left">
                                <FormField label={dictionary.SELL_START[language]} invalid={this.isInvalid("sellStart")}>
                                    <input type="date" max="2999-12-31" name="sellStart" placeholder={dictionary.DATE[language]} value={this.props.sellStart} onChange={this.onInputChange} />
                                </FormField>
                            </div>
                            <div className="col-right">
                                <FormField label={dictionary.ESTATE_COUNT[language]} invalid={this.isInvalid("residentsCount")}>
                                    <input type="number" name="residentsCount" placeholder={dictionary.AMOUNT[language]} value={this.props.residentsCount || undefined} onChange={this.onInputChange} />
                                </FormField>
                            </div>
                        </div>
                    </div>


                    <div className="container">
                        <FormField dark label={dictionary.PROJECT_IMAGE[language]} >
                            <img src={this.props.projectImage} alt="Avatar" className="avatarbig" style={{ "margin": "auto" }} />
                            <div className="button-group">
                                <Button primary onClick={this.showUpload}>{dictionary.CHOOSE_IMAGE[language]}...</Button>
                            </div>

                        </FormField>
                    </div>
                    <FormField dark label={dictionary.COMMON_INFO[language]} invalid={this.isInvalid("description")}>
                        <textarea name="description" placeholder={dictionary.DESCRIPTION[language]} value={this.props.description} onChange={this.onInputChange} />
                    </FormField>
                    <div>
                        <FormField label={dictionary.SITE_MANAGER[language]} dark>
                            <input
                                placeholder={dictionary.SITE_MANAGER[language]}
                                onChange={this.onResponsibleChanged}
                                value={this.props.responsibleSelected}
                            />
                        </FormField>
                    </div>
                    <div className="twoColsContainer">
                        <div className="col-left">
                            <FormField label={dictionary.SUPERVISOR[language]} dark>
                                <input
                                    placeholder={dictionary.SUPERVISOR[language]}
                                    onChange={this.onSupervisorChanged}
                                    value={this.props.supervisorSelected}
                                />
                            </FormField>
                        </div>
                        <div className="col-right">
                            <FormField label={dictionary.CONSTRUCTION_MANAGER[language]} dark>
                                <input
                                    placeholder={dictionary.CONSTRUCTION_MANAGER[language]}
                                    onChange={this.onContractorChanged}
                                    value={this.props.contractorSelected}
                                />
                            </FormField>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}