import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import Button from '../../../Public/Button'
import Icon from '../../../Public/Icon'

export default class UploadImageForm extends Component {
    state = {
        accepted: [],
        dictionary:undefined
    }

    componentDidMount(){
        Promise.all([
            import('../../../../helpers/translations').then(x => x.MyUser)
        ])
        .then(res => {
            let dictionary = {}
            res.forEach(e => Object.assign(dictionary, e))
            this.setState({dictionary}) 
        })
    }

    uploadImage = () => {
        this.props.uploadImage && this.props.uploadImage(this.state.accepted)
        this.props.cancel()
    }

    onCancel = () => {
        this.props.cancel()
    }



    preView = () => {
        if (this.state.accepted) {
            return (
                this.state.accepted.map(file => (
                    <div key={file.name}>
                        <div >
                            <img src={file.preview} alt="Avatar" className="avatarbig" />
                        </div>
                    </div>
                ))
            );
        }
        return (<div />);
    }

    onDrop = (accepted, rejected) => {
        const ok = [".png", ".jpg", ".jpeg"]

        accepted = accepted.filter(file => ok.find(str => file.name.toLowerCase().indexOf(str) > -1))
        accepted.forEach(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));

        this.setState({ accepted });
    }

    render() {
        const{ language} = this.props
        const {dictionary} = this.state
        if(!dictionary) return <div> ... </div>
        return (
            <div className="upload-form" >
                <div>
                    <h1>{dictionary.UPLOAD_IMAGE_HEADING[language]}</h1>
                    <p>{dictionary.UPLOAD_IMAGE_RULES[language]}</p>
                    <div>
                        <Dropzone onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()} style={{ "backgroundColor" : "#f8f8f9" , "padding": "8px", "textAlign": "center" }}>
                                        <input {...getInputProps()} />
                                        <Icon name="download cloud" height="40" />
                                        <h1>{dictionary.DRAG_AND_DROP_IMAGE[language]}</h1>
                                        <h5>{dictionary.OR_CLICK[language]}</h5>
                                    </div>
                                </section>
                            )}

                        </Dropzone>
                        <div style={{ "textAlign": "center", "margin": "8px" }}>
                            {
                                this.preView()
                            }
                        </div>
                    </div>
                </div>
                <div className="order-button-pane">
                    <Button primary onClick={this.uploadImage} disabled={this.state.accepted.length === 0}>OK</Button>
                    <Button onClick={this.onCancel}>{dictionary.CANCEL[language]}</Button>
                </div>
            </div>
        )
    }
}