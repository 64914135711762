// Public
export const HOME = "/"
export const WHY_BB = "/why"
export const ABOUT = "/about"
export const ORDER = "/order"
export const LOGIN = "/login"

const production = 'https://byggbankenapi.azurewebsites.net';
const localdev = 'https://localhost:44360';
export const api = window.location.hostname.startsWith('localhost') ? localdev : production;

