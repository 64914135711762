import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css'
import 'moment/locale/sv';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createHistory from 'history/createBrowserHistory'
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router'
import thunk from 'redux-thunk'
import Loadable from 'react-loadable'

import * as reducers from './store/reducers'

import * as serviceWorker from './serviceWorker';

import Routes, { DefaultLoader } from './Routes'
// import NavBarContainer from './containers/NavBarContainer'
// import App, { LanguageContext } from './App'
const composeEnhancers = composeWithDevTools;

const history = createHistory();
const middleware = routerMiddleware(history);
const store = createStore(
    connectRouter(history)(
        combineReducers({ ...reducers })
    ),
    compose(
        composeEnhancers(applyMiddleware(
            middleware, thunk
        ))
    )
)

const AsyncNavBarContainer = Loadable.Map({
    loader: {
        Container: () => import('./containers/NavBarContainer'),
        copy: () => import('./helpers/translations').then(x => x.navBarDictionary),
        Notifications: () => import('./helpers/translations').then(x => x.Notifications),
    },
    render(loaded, props) {
        const bla = {};
        for(let k in loaded){
            if(k === "Container") continue;
            Object.assign(bla, loaded[k])
        }
        const nprops = Object.assign({}, props, {dictionary:bla})
        return <loaded.Container.default {...nprops}  >{props.children}</loaded.Container.default>
    },
    loading: DefaultLoader,
    delay: 300,
    timeout: 5000
})


ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history} >
            <div>
                <AsyncNavBarContainer>
                    <Routes />
                </AsyncNavBarContainer>
                {/* <AsyncFooter /> */}
            </div>
        </ConnectedRouter>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
