

export const GETTING_PROJECTS_PENDING = "projects.GETTING_PROJECTS_PENDING";
export const GETTING_PROJECTS_SUCCESS = "projects.GETTING_PROJECTS_SUCCESS";
export const GETTING_PROJECTS_FAILED = "projects.GETTING_PROJECTS_FAILED";

export const GETTING_PROJECT_PENDING = "projects.GETTING_PROJECT_PENDING";
export const GETTING_PROJECT_SUCCESS = "projects.GETTING_PROJECT_SUCCESS";
export const GETTING_PROJECT_FAILED = "projects.GETTING_PROJECT_FAILED";


export const CREATE_PROJECT_SUCCESS ="projects.CREATE_PROJECT_SUCCESS"
export const CREATE_PROJECT_PENDING ="projects.CREATE_PROJECT_PENDING"
export const CREATE_PROJECT_FAILED ="projects.CREATE_PROJECT_FAILED"

export const EDIT_PROJECT_SUCCESS ="projects.EDIT_PROJECT_SUCCESS"
export const EDIT_PROJECT_PENDING ="projects.EDIT_PROJECT_PENDING"
export const EDIT_PROJECT_FAILED ="projects.EDIT_PROJECT_FAILED"


export const GET_DEFAULTFOLDERS_SUCCESS = 'projects.GET_DEFAULTFOLDERS_SUCCESS'

export const DELETE_FOLDER_PENDING = 'projects.DELETE_FOLDER_PENDING'
export const DELETE_FOLDER_SUCCESS = 'projects.DELETE_FOLDER_SUCCESS'
export const DELETE_FOLDER_FAILED = 'projects.DELETE_FOLDER_FAILED'


export const CREATE_FOLDER_PENDING = 'projects.CREATE_FOLDER_PENDING'
export const CREATE_FOLDER_FAILED = 'projects.CREATE_FOLDER_FAILED'
export const CREATE_FOLDER_SUCCESS = 'projects.CREATE_FOLDER_SUCCESS'

export const COMPANIES_SUCCESS = 'projects.COMPANIES_SUCCESS' 


export const ARCHIVE_REQEUSTING = 'projects.ARCHIVE_REQEUSTING' 
export const ARCHIVE_SUCCESS = 'projects.ARCHIVE_SUCCESS' 
export const ARCHIVE_FAILED = 'projects.ARCHIVE_FAILED' 

export const RESTORE_REQEUSTING = 'projects.RESTORE_REQEUSTING' 
export const RESTORE_SUCCESS = 'projects.RESTORE_SUCCESS' 
export const RESTORE_FAILED = 'projects.RESTORE_FAILED' 

export const ACTIVITY_LOG_GET_SUCCESS = 'projects.ACTIVITY_LOG_GET_SUCCESS'
export const ACTIVITY_LOG_GET_FAILED = 'projects.ACTIVITY_LOG_GET_FAILED'

export const PM_GET_SUCCESS = 'projects.PM_GET_SUCCESS'
export const PM_GET_FAILED = 'projects.PM_GET_FAILED'
export const PM_SENT = 'projects.PM_SENT'

export const NOTIFICATIONS_GET_SUCCESS = 'projects.NOTIFICATIONS_GET_SUCCESS'
export const NOTIFICATION_IGNORES_GET_SUCCESS = 'projects.NOTIFICATION_IGNORES_GET_SUCCESS'
export const NOTIFICATION_IGNORES_UPDATE_SUCCESS = 'projects.NOTIFICATION_IGNORES_UPDATE_SUCCESS'

export const PROJECTS_REQUESTING = 'projects.REQUESTING'

export const ADD_FAVOURITE = "projects.ADD_FAVOURITE";
export const REMOVE_FAVOURITE = "projects.REMOVE_FAVOURITE";

export const SEARCH_GET_SUCCESS = "projects.SEARCH_GET_SUCCESS";

export const ATA_GET_SUCCESS = 'projects.ATA_GET_SUCCESS'
export const ATA_GET_FAILED = 'projects.ATA_GET_FAILED'
export const ATA_CREATE_SUCCESS = 'projects.ATA_CREATE_SUCCESS'
export const ATA_CREATE_FAILED = 'projects.ATA_CREATE_FAILED'
export const ATA_UPDATE_SUCCESS = 'projects.ATA_UPDATE_SUCCESS'
export const ATA_UPDATE_FAILED = 'projects.ATA_UPDATE_FAILED'

export const SET_FOLDER_TO_VISIT = 'projects.SET_FOLDER_TO_VISIT'


export const NAME_EXISTS = 'projects.NAME_EXISTS'

export const CLEAR_ERROR = 'projects.CLEAR_ERROR'