import Immutable from 'seamless-immutable'
import * as projectsTypes from './actionTypes';

const initState = Immutable({
    projects: [],
    selectedProject: undefined,
    isFetchingData: false,
    isCreating: false,
    isDeletingFolder: false,
    isCreatingFolder: false,
    project: {},
    defaultFolders: [],
    fileCategories: [],
    createdId: "",
    companies: [],
    activityLogs: [],
    pms: [],
    notifications: [],
    excludedNotifications: [],
    searchFiles: [],
    atas: [],
    folderToVisit: undefined,
    projectNameError: ""
})

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        // This should actually be used for any requests that isnt very specific.... 
        case projectsTypes.CLEAR_ERROR:
            return state.merge({
                projectNameError: ""
            })
        case projectsTypes.NAME_EXISTS:
            return state.merge({
                projectNameError: action.payload
            })
        case projectsTypes.PROJECTS_REQUESTING:
            return state.merge({
                isFetchingData: true
            })
        case projectsTypes.GET_DEFAULTFOLDERS_SUCCESS:
            return state.merge({
                defaultFolders: action.payload
            })
        case projectsTypes.GETTING_PROJECT_PENDING:
        case projectsTypes.GETTING_PROJECTS_PENDING:
            return state.merge({
                isFetchingData: true
            });
        case projectsTypes.GETTING_PROJECTS_SUCCESS:

            return state.merge({
                projects: action.payload,
                isFetchingData: false
            });
        case projectsTypes.GETTING_PROJECT_SUCCESS:
            return state.merge({
                project: action.payload,
                isFetchingData: false
            });
        case projectsTypes.GETTING_PROJECTS_FAILED:
            return state.merge({
                isFetchingData: false,
                // Some kind of error handling
            })
        case projectsTypes.CREATE_PROJECT_PENDING:
            return state.merge({
                isCreating: true
            })
        case projectsTypes.CREATE_PROJECT_SUCCESS:
            return state.merge({
                // project: action.payload,
                isCreating: false,
                createdId: action.payload
            })
        case projectsTypes.CREATE_PROJECT_FAILED:
            return state.merge({
                isCreating: false
            })
        case projectsTypes.DELETE_FOLDER_PENDING:
            return state.merge({
                isDeletingFolder: true
            })
        case projectsTypes.DELETE_FOLDER_SUCCESS:
        case projectsTypes.DELETE_FOLDER_FAILED:
            return state.merge({
                isDeletingFolder: false
            })

        case projectsTypes.CREATE_FOLDER_PENDING:
            return state.merge({
                isCreatingFolder: true
            })
        case projectsTypes.CREATE_FOLDER_SUCCESS:
        case projectsTypes.CREATE_FOLDER_FAILED:
            return state.merge({
                isCreatingFolder: false
            })
        case projectsTypes.COMPANIES_SUCCESS:
            return state.merge({
                companies: action.payload
            })

        case projectsTypes.ARCHIVE_REQEUSTING:
        case projectsTypes.RESTORE_REQEUSTING:
            return state.merge({
                isFetchingData: true
            })
        case projectsTypes.ARCHIVE_SUCCESS:
        case projectsTypes.ARCHIVE_FAILED: // TODO HANDLE
        case projectsTypes.RESTORE_FAILED: // TODO HANDLE
        case projectsTypes.RESTORE_SUCCESS:
        case projectsTypes.NOTIFICATION_IGNORES_UPDATE_SUCCESS:
            return state.merge({
                isFetchingData: false
            })
        case projectsTypes.ACTIVITY_LOG_GET_SUCCESS:
            return state.merge({
                activityLogs: action.payload
            })
        case projectsTypes.PM_GET_SUCCESS:
            return state.merge({
                pms: action.payload
            })
        case projectsTypes.PM_SENT:
            return state.merge({
                pms: [...state.pms, action.payload]
            })
  
        case projectsTypes.NOTIFICATIONS_GET_SUCCESS:
            return state.merge({
                notifications: action.payload,
                isFetchingData: false
            })
        case projectsTypes.NOTIFICATION_IGNORES_GET_SUCCESS:
            return state.merge({
                excludedNotifications: action.payload
            })

        case projectsTypes.ADD_FAVOURITE:
            let project = state.projects.find(p => p.id === action.payload);
            var stuff = { ...state, projects: [...state.projects.filter(p => p.id !== action.payload), { ...project, isFavourite: true }] }
            let projects = stuff.projects.slice();
            projects = projects.sort(function (x, y) {
                if (x.isFavourite === y.isFavourite) {
                    return x.sortOrder - y.sortOrder;
                }
                return x.isFavourite ? -1 : 1;
            });
            return state.merge({ projects: projects });

        case projectsTypes.REMOVE_FAVOURITE:
            project = state.projects.find(p => p.id === action.payload);
            stuff = { ...state, projects: [...state.projects.filter(p => p.id !== action.payload), { ...project, isFavourite: false }] }
            projects = stuff.projects.slice();
            projects = projects.sort(function (x, y) {
                if (x.isFavourite === y.isFavourite) {
                    return x.sortOrder - y.sortOrder;
                }
                return x.isFavourite ? -1 : 1;
            });
            return state.merge({ projects: projects });
        case projectsTypes.SEARCH_GET_SUCCESS:
            return state.merge({ searchFiles: action.payload });
        case projectsTypes.ATA_GET_SUCCESS:
            return state.merge({ atas: action.payload });
        case projectsTypes.SET_FOLDER_TO_VISIT:
            return state.merge({
                folderToVisit: action.payload
            })
        default:
            return state;
    }
}

