import React, { Component } from 'react'
import './style.css'

export default class Button extends Component {

    onClick = e => {
        e.preventDefault();
        if (this.props.disabled || !this.props.onClick) return;
        this.props.onClick(e);
    }
    render() {
        let classname = "btn";
        if (this.props.disabled) {
            classname += " inactive"
        } 
        if (this.props.primary) {
            classname += " primary"
        } else if(this.props.black){
            classname += " black"
        } else if(this.props.lowerOpacity){
            classname += ' lower-opacity'
        }
        // const classname = this.props.primary ? "primary btn" : this.props.disabled ? "inactive btn" : "btn";
        return (
            <button className={classname} onClick={this.onClick} style={this.props.style}><span className="btn-span">{this.props.children}</span></button>
        )
    }
}

