import React from 'react'
import { connect } from "react-redux";
import { Route } from 'react-router'
import { Redirect } from 'react-router-dom'
import auth from './helpers/auth';


const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => {
      return (
        // rest.user && rest.user.access_token ?
        auth.getToken() ?
          <Component {...props} /> :
          <Redirect to="/" />
      )
    }} />
  )

  const mapStateToProps = (state) => {
    return {
      user: state.user
    }
  }
  
  export default connect(mapStateToProps)(PrivateRoute)