import Immutable from 'seamless-immutable'
import * as fileTypes from './actionTypes'
const initState = Immutable({
    isCreatingStatus: false,
    isCreatingFileCategory: false,
    statusOptions: [
        { value: "1", label: "Arbetshandling (AH)" },
        { value: "2", label: "Arbetskopia (AK)" },
        { value: "3", label: "Bygghandling (BH)" },
        { value: "4", label: "Bygglov (BL)" },
        { value: "5", label: "Förstudie (F)" },
        { value: "6", label: "Förfrågningshandling (FH)" },
        { value: "7", label: "Förhandskopia (FK)" },
        { value: "8", label: "Förfrågningsunderlag (FU)" },
        { value: "9", label: "Förvaltningshandling (FV)" },
        { value: "10", label: "Förslagshandling (FÖ)" },
        { value: "11", label: "Granskningshandling (GH)" },
        { value: "12", label: "Huvudhandling (HH)" },
        { value: "13", label: "Informationshandling (IH)" },
        { value: "14", label: "Kalkylhandling (KH)" },
        { value: "15", label: "Marklov (ML)" },
        { value: "16", label: "Programhandling (P)" },
        { value: "17", label: "Preliminärhandling (PH)" },
        { value: "18", label: "Ramhandling (R)" },
        { value: "19", label: "Relationshandling (RH)" },
        { value: "20", label: "Rivningslov (RL)" },
        { value: "21", label: "Skiss (S)" },
        { value: "22", label: "Systemhandling (SH)" },
    ],
    fileCategories: [

    ],
    filesToUpload: 0,
    isUploadingFiles: false,
    files: [],
    isRemoving: false
})

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case fileTypes.DESC_CAT_CREATE_PENDING:
            return state.merge({
                isCreatingFileCategory: true
            });
        case fileTypes.DESC_CAT_CREATE_SUCCESS:
            return state.merge({
                fileCategories: [...state.fileCategories, action.payload],
                isCreatingFileCategory: false
            })

        case fileTypes.FILE_UPLOAD_SET_COUNT:
            let isUploadingFiles = action.payload && action.payload > 0 ? true : false;
            let filesToUpload = action.payload ? action.payload : 0;
            return state.merge({
                filesToUpload,
                isUploadingFiles
            })
        case fileTypes.FILE_UPLOAD_SUCCESS:
            let updatedFilesToUpload = state.filesToUpload - 1;
            let updatedIsUploadingFiles = filesToUpload <= 0;
            return state.merge({
                filesToUpload: updatedFilesToUpload,
                isUploadingFiles: updatedIsUploadingFiles
            })
        case fileTypes.GET_FILECATEGORIES_SUCCESS:
            return state.merge({
                fileCategories: action.payload
            })
        case fileTypes.GET_FILES_SUCCESS:
            return state.merge({
                files: action.payload
            })
        case fileTypes.FILE_DELETE_REQUESTING:
            return state.merge({
                isRemoving: true
            })
        case fileTypes.FILE_DELETE_FAILED: //todo
        case fileTypes.FILE_DELETE_SUCCESS:
            return state.merge({
                isRemoving: false
            })
        default:
            return state;
    }
}