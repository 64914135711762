import React,{Component} from 'react'
import PropTypes from 'prop-types'
import './style.css'

class FormField extends Component{
    render(){
        let className = `form-field ${this.props.dark ? "dark" : ""}`
        if(this.props.invalid){
            className+=" error"
        }
        return(
            <div className={className} style={this.props.style}>
                <label className="form-field-label">{this.props.label}</label>
                {this.props.children}
                {this.props.invalid && this.props.errortext && <p style={{color:"crimson", height:"20px !important"}}>{this.props.errortext}</p>}
            </div>
        )
    }
}

FormField.propTypes = {
    label:PropTypes.string
}

export default FormField;