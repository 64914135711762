export const MESSAGES_IS_REQUESTING = 'messages.MESSAGES_IS_REQUESTING'

export const MESSAGE_PENDING = "message.SEND_MESSAGE_PENDING";
export const MESSAGE_SUCCESS = "message.SEND_MESSAGE_SUCCESS";
export const MESSAGE_FAILED = "message.SEND_MESSAGE_FAILED";

export const CREATE_CONVERSATION_PENDING = "conversation.CREATE_PENDING";
export const CREATE_CONVERSATION_SUCCESS = "conversation.CREATE_SUCCESS";
export const CREATE_CONVERSATION_FAILED = "conversation.CREATE_FAILED";

export const GET_CONVERSATION_LIST_PENDING = "conversation.GET_LIST_PENDING";
export const GET_CONVERSATION_LIST_SUCCESS = "conversation.GET_LIST_SUCCESS";
export const GET_CONVERSATION_LIST_FAILED = "conversation.GET_LIST_FAILED";

export const GET_CONVERSATION_PENDING = "conversation.GET_PENDING";
export const GET_CONVERSATION_SUCCESS = "conversation.GET_SUCCESS";
export const GET_CONVERSATION_FAILED = "conversation.GET_FAILED";

export const ARCHIVE_CONVERSATION_PENDING = "conversation.ARCHIVE_PENDING";
export const ARCHIVE_CONVERSATION_SUCCESS = "conversation.ARCHIVE_SUCCESS";
export const ARCHIVE_CONVERSATION_FAILED = "conversation.ARCHIVE_FAILED";

export const GET_ALL_USERS_PENDING = "message.GET_USERS_PENDING";
export const GET_ALL_USERS_SUCCESS = "message.GET_USERS_SUCCESS";
export const GET_ALL_USERS_FAILED = "message.GET_USERS_FAILED";



