

export const languages = {
    English: "en-US", Swedish: "sv-SE"
}

export const languageTranslation = {
    [languages.Swedish]: "Svenska",
    [languages.English]: "English"
}

export const navBarDictionary = {
    WHY_BUILDING_BANK: {
        [languages.English]: "Why building bank",
        [languages.Swedish]: "Varför byggbanken"
    },
    ABOUT: {
        [languages.English]: "About",
        [languages.Swedish]: "Om oss"
    },
    ORDER: {
        [languages.English]: "Order",
        [languages.Swedish]: "Beställ"
    },
    HELP_CENTER: {
        [languages.English]: "Help center",
        [languages.Swedish]: "Hjälpcenter"
    },
    PROJECTS: {
        [languages.English]: "Projects",
        [languages.Swedish]: "Projekt"
    },
    MEMBERS: {
        [languages.English]: "Members",
        [languages.Swedish]: "Deltagare"
    },
    EDIT_PROJECT: {
        [languages.English]: "Edit project",
        [languages.Swedish]: "Redigera projekt"
    },
    FILES: {
        [languages.English]: "Files",
        [languages.Swedish]: "Filer"
    }
}


export const defaultCategorySubCategoryDictionary = {
    ACCOUSTIC: {
        [languages.English]: "Accoustic",
        [languages.Swedish]: "Akustik"
    },
    ARCHITECTURE: {
        [languages.English]: "Architecture",
        [languages.Swedish]: "Arkitektur"
    },
    FIRE: {
        [languages.English]: "Fire",
        [languages.Swedish]: "Brand"
    },
    BUILDING: {
        [languages.English]: "Building",
        [languages.Swedish]: "Bygg"
    },
    DATA_TELE: {
        [languages.English]: "Data & Tele",
        [languages.Swedish]: "Data & Tele"
    },
    ELECTRICITY: {
        [languages.English]: "Electricity",
        [languages.Swedish]: "El"
    },
    // ELECTRICITY_GROUND: {
    //     [languages.English]: "Electricity ground",
    //     [languages.Swedish]: "El-Mark"
    // },
    ENERGY: {
        [languages.English]: "Energy",
        [languages.Swedish]: "Energi"
    },
    PROPERTY_INFO: {
        [languages.English]: "Property info",
        [languages.Swedish]: "Fastighetsinfo"
    },
    DAMPNESS: {
        [languages.English]: "Dampness",
        [languages.Swedish]: "Fukt"
    },
    GAS: {
        [languages.English]: "Gas",
        [languages.Swedish]: "Gas"
    },
    GEO_TECHNICS: {
        [languages.English]: "Geotechnics",
        [languages.Swedish]: "Geoteknik"
    },
    ELEVATOR: {
        [languages.English]: "Elevator",
        [languages.Swedish]: "Hiss"
    },
    TENANT: {
        [languages.English]: "Tenant",
        [languages.Swedish]: "Hyresgäst"
    },
    FURNISHING: {
        [languages.English]: "Furnishing",
        [languages.Swedish]: "Inredning"
    },
    CONSTRUCTION: {
        [languages.English]: "Construction",
        [languages.Swedish]: "Konstruktion"
    },
    CONSTRUCTION_GROUND: {
        [languages.English]: "Construction ground",
        [languages.Swedish]: "Konstruktion-mark"
    },
    FROST: {
        [languages.English]: "Frost",
        [languages.Swedish]: "Kyla"
    },
    LANDSCAPE: {
        [languages.English]: "Landscape",
        [languages.Swedish]: "Landskap"
    },
    GROUND: {
        [languages.English]: "Ground",
        [languages.Swedish]: "Mark"
    },
    METROLOGY: {
        [languages.English]: "Metrology",
        [languages.Swedish]: "Mätteknik"
    },
    ENVIRONMENT: {
        [languages.English]: "Environment",
        [languages.Swedish]: "Miljö"
    },
    PREFAB: {
        [languages.English]: "Prefab",
        [languages.Swedish]: "Prefab"
    },
    PROJECT_COMMON: {
        [languages.English]: "Project common",
        [languages.Swedish]: "Projekt-gemensamt"
    },
    PIPES: {
        [languages.English]: "Pipes",
        [languages.Swedish]: "Rör"
    },
    SECURITY: {
        [languages.English]: "Security",
        [languages.Swedish]: "Säkerhet"
    },
    SPRINKLER: {
        [languages.English]: "Sprinkler",
        [languages.Swedish]: "Sprinkler"
    },
    FRAMEWORK: {
        [languages.English]: "Framework",
        [languages.Swedish]: "Stomme"
    },
    LARGE_KITCHEN: {
        [languages.English]: "Large kitchen",
        [languages.Swedish]: "Storkök"
    },
    CONTROL_RULES_SURVEILLANCE: {
        [languages.English]: "Control, rules and surveillance",
        [languages.Swedish]: "Styr, regler och övervakning"
    },
    AVAILABILITY: {
        [languages.English]: "Availability",
        [languages.Swedish]: "Tillgänglighet"
    },
    TRAFFIC_DESIGN: {
        [languages.English]: "Traffic design",
        [languages.Swedish]: "Traffikdesign"
    },
    VA_GROUND: {
        [languages.English]: "VA-Ground",
        [languages.Swedish]: "VA-Mark"
    },
    HEAT_SANITATION: {
        [languages.English]: "Heat and sanitation",
        [languages.Swedish]: "Värme och sanitet"
    },
    HEAT_VENTILATION_SANITATION: {
        [languages.English]: "Heat, ventilation and sanitation",
        [languages.Swedish]: "Värme, ventilation och sanitet"
    },
    VENTILATION: {
        [languages.English]: "Ventilation",
        [languages.Swedish]: "Ventilation"
    }
}

export const defaultCategoriesDictionary = {
    ACTIVITY_LOG: {
        [languages.English]: "Activity log",
        [languages.Swedish]: "Aktivitetslog"
    },
    PARTICIPANTS: {
        [languages.English]: "Participants",
        [languages.Swedish]: "Deltagare"
    },
    DISTRIBUTION_LISTS: {
        [languages.English]: "Distribution lists",
        [languages.Swedish]: "Distributionslistor"
    },
    ADMINISTRATIVE_PRESCRIPTIONS: {
        [languages.English]: "Administrative prescriptions",
        [languages.Swedish]: "Administrativa Föreskrifter"
    },
    PROJECT_PRESUMPTIONS: {
        [languages.English]: "Project presumptions",
        [languages.Swedish]: "Projektförutsättningar"
    },
    TIMEPLAN: {
        [languages.English]: "Timeplan",
        [languages.Swedish]: "Tidsplan"
    },
    QA: {
        [languages.English]: "Q & A",
        [languages.Swedish]: "Fråga / Svar"
    },
    ÄTA: {
        [languages.English]: "ÄTA",
        [languages.Swedish]: "Variations"
    },
    PROTOCOL: {
        [languages.English]: "Protocol",
        [languages.Swedish]: "Protokoll"
    },
    DESCRIPTIONS: {
        [languages.English]: "Descriptions",
        [languages.Swedish]: "Beskrivningar"
    },
    PM: {
        [languages.English]: "PM",
        [languages.Swedish]: "PM"
    },
    // LISTS: {
    //     [languages.English]: "Lists",
    //     [languages.Swedish]: "Företeckningar"
    // },
    CAD_FILES: {
        [languages.English]: "CAD files",
        [languages.Swedish]: "CAD-filer"
    },
    BLUEPRINTS: {
        [languages.English]: "Blueprints",
        [languages.Swedish]: "Ritningar"
    },
    KMA: {
        [languages.English]: "Quality and environment",
        [languages.Swedish]: "Kvalitets- och miljösäkring"
    },
    DoU_INSTRUCTIONS: {
        [languages.English]: "DoU-instructions",
        [languages.Swedish]: "DoU-instruktioner"
    },
    INSPECTIONS: {
        [languages.English]: "Inspections",
        [languages.Swedish]: "Besktningar"
    },
    CONTRACTS: {
        [languages.English]: "Contracts",
        [languages.Swedish]: "Avtal"
    },
    ECONOMY: {
        [languages.English]: "Economy/Budget",
        [languages.Swedish]: "Ekonomi/Budget"
    },
    PICTURES: {
        [languages.English]: "Pictures",
        [languages.Swedish]: "Bilder"
    },
    AUTHORITIES: {
        [languages.English]: "Authorities",
        [languages.Swedish]: "Myndigheter"
    }
}

export const selectDictionary = {
    NO_OPTIONS_MSG: {
        [languages.English]: "No options",
        [languages.Swedish]: "Inga alternativ"
    },
    SELECT: {
        [languages.English]: "Select...",
        [languages.Swedish]: "Välj..."
    },
    SELECT_SUBCAT: {
        [languages.English]: "Select subcategory...",
        [languages.Swedish]: "Välj underkategori..."
    },
    ADD: {
        [languages.English]: "Add...",
        [languages.Swedish]: "Lägg till..."
    }
}

export const protocolCategoryDictionary = {
    CLIENT_MEETING: {
        [languages.English]: "Client meeting",
        [languages.Swedish]: "Beställarmöte"
    },
    PLANNING_MEETING: {
        [languages.English]: "Planning meeting",
        [languages.Swedish]: "Byggmöte"
    },
    CAD_MEETING: {
        [languages.English]: "CAD Co-ordination meeting",
        [languages.Swedish]: "CAD samordningsmöte"
    },
    CONSULTANT_MEETING: {
        [languages.English]: "Consultant meeting",
        [languages.Swedish]: "Konsultmöte"
    },
    PROJECTION_MEETING: {
        [languages.English]: "Projection meeting",
        [languages.Swedish]: "Projekteringsmöte"
    },
    COORDINATION_MEETING: {
        [languages.English]: "Co-ordination meeting",
        [languages.Swedish]: "Samordningmöte"
    },
    WEEK_MEETING: {
        [languages.English]: "Week meeting",
        [languages.Swedish]: "Veckomöte"
    }
}

export const projectPresumptionsCategoriyDictionary = {

    PLANNING_PERMISSION: {
        [languages.English]: "Planning premissions",
        [languages.Swedish]: "Bygglov"
    },
    CONTRACT_ACTIONS: {
        [languages.English]: "Contract actions",
        [languages.Swedish]: "Kontraktshandlingar"
    },
    AUTHORITIES: {
        [languages.English]: "Authorities",
        [languages.Swedish]: "Myndigheter"
    },
    RELATION_BLUEPRINTS: {
        [languages.English]: "Relation blueprints",
        [languages.Swedish]: "Relationsritningar"
    },
    ROOM_FUNCTION_PROGRAM: {
        [languages.English]: "Room function program",
        [languages.Swedish]: "Rumfunktionsprogram"
    }
}


export const kmaDictionary = {
    WORK_ENVIRONMENT: {
        [languages.English]: "Work environment",
        [languages.Swedish]: "Arbetsmiljö"
    },
    QUALITY: {
        [languages.English]: "Quality",
        [languages.Swedish]: "Kvalitet"
    },
    ENVIRONMENT: {
        [languages.English]: "Environment",
        [languages.Swedish]: "Miljö"
    }
}

export const DoUDictionary = {
    BUILDING: {
        [languages.English]: "Building",
        [languages.Swedish]: "Bygg"
    },
    ELECTRICITY_INSTALLATION: {
        [languages.English]: "Electricity installation",
        [languages.Swedish]: "Elinstallation"
    },
    ELEVATORS: {
        [languages.English]: "Elevators",
        [languages.Swedish]: "Hissar"
    },
    FRIDGE_INSTALLATION: {
        [languages.English]: "Fridge installation",
        [languages.Swedish]: "Kylinstallation"
    },
    AIR_TREATMENT_INSTALLATION: {
        [languages.English]: "Air treatment installation",
        [languages.Swedish]: "Luftbehandlingsinstallation"
    },
    PIPE_INSTALLATION: {
        [languages.English]: "Pipe installation",
        [languages.Swedish]: "Rörinstallation"
    },
    SPRINKLER_INSTALLATION: {
        [languages.English]: "Sprinkler installation",
        [languages.Swedish]: "Sprinklerinstallation"
    },
    CONTROL_INSTALLATION: {
        [languages.English]: "Control installation",
        [languages.Swedish]: "Styrinstallation"
    }
}

export const inspectionDictionary = {
    AFTER_INSPECTIONS: {
        [languages.English]: "After inspections",
        [languages.Swedish]: "Efterbesiktningar"
    },
    BEFORE_INSPECTIONS: {
        [languages.English]: "Before inspections",
        [languages.Swedish]: "Förbesiktningar"
    },
    END_INSPECTIONS: {
        [languages.English]: "End inspections",
        [languages.Swedish]: "Slutbesiktningar"
    }
}

export const projectCreateFormDictionary = {
    ADD_ALL_BUTTON: {
        [languages.English]: "Add all",
        [languages.Swedish]: "Lägg till alla"
    },
    CREATE_NEW_FOLDER: {
        [languages.English]: "Create new folder",
        [languages.Swedish]: "Skapa ny mapp"
    },
    REMOVE: {
        [languages.English]: "Remove",
        [languages.Swedish]: "Ta bort"
    },
    REMOVE_ALL: {
        [languages.English]: "Remove all",
        [languages.Swedish]: "Ta bort alla"
    },
    CREATE_PROJECT: {
        [languages.English]: "Create project",
        [languages.Swedish]: "Skapa projekt"
    },
    NEW_PROJECT: {
        [languages.English]: "New project",
        [languages.Swedish]: "Nytt projekt"
    },
    ARE_YOU_SURE_YOU_WANT_TO_LEAVE: {
        [languages.English]: 'You have made changes which are unsaved, if you continue they will disappear. Do you want to continue?',
        [languages.Swedish]: 'Du har gjort ändringar som inte är sparade, fortsätter du försvinner dessa ändringar. Vill du fortsätta?'
    }

}

export const projectDictionary = {
    NAME: {
        [languages.English]: "Name",
        [languages.Swedish]: "Namn"
    },
    REV: {
        [languages.English]: "Rev",
        [languages.Swedish]: "Rev"
    },
    STATUS: {
        [languages.English]: "Status",
        [languages.Swedish]: "Status"
    },
    DESCRIPTION: {
        [languages.English]: "Description",
        [languages.Swedish]: "Beskrivning"
    },
    SCALE: {
        [languages.English]: "Scale",
        [languages.Swedish]: "Skala"
    },
    DATE: {
        [languages.English]: "Date",
        [languages.Swedish]: "Datum"
    },
    REV_DATE: {
        [languages.English]: "Rev Date",
        [languages.Swedish]: "Rev Datum"
    },
}

export const projectListDictionary = {
    UPDATED: {
        [languages.English]: "Updated",
        [languages.Swedish]: "Uppdaterad"
    },
    MEMBERS: {
        [languages.English]: "Members",
        [languages.Swedish]: "Deltagare"
    },
    FILES: {
        [languages.English]: "Files",
        [languages.Swedish]: "Filer"
    },
    EDIT: {
        [languages.English]: "Edit",
        [languages.Swedish]: "Redigera"
    },
    OPEN: {
        [languages.English]: "Open",
        [languages.Swedish]: "Öppna"
    },
    NEW_PROJECT: {
        [languages.English]: "New project",
        [languages.Swedish]: "Nytt projekt"
    },
    MY_PROJECTS: {
        [languages.English]: "My projects",
        [languages.Swedish]: "Mina projekt"
    },
    SEARCH:{
        [languages.English]: "Search",
        [languages.Swedish]: "Sök"
    }
}

export const loginForm = {
    LOGIN_HEADER: {
        [languages.English]: "Login",
        [languages.Swedish]: "Logga in"
    },
    WELCOME_BACK: {
        [languages.English]: "Welcome back!",
        [languages.Swedish]: "Välkommen tillbaka!"
    },
    EMAIL: {
        [languages.English]: "Email",
        [languages.Swedish]: "Epost"
    },
    PASSWORD: {
        [languages.English]: "Password",
        [languages.Swedish]: "Lösenord"
    },
    REPEAT_PASSWORD:{
        [languages.English]: "Repeat password",
        [languages.Swedish]: "Repetera lösenord"
    },
    FORGOT_PASSWORD: {
        [languages.English]: "Forgot your password?",
        [languages.Swedish]: "Glömt ditt lösenord?"
    },
    REGISTER_HEADER: {
        [languages.English]: "Register",
        [languages.Swedish]: "Registrera"
    },
    FIRSTNAME: {
        [languages.English]: "First name",
        [languages.Swedish]: "Förnamn"
    },
    LASTNAME: {
        [languages.English]: "Last name",
        [languages.Swedish]: "Efternamn"
    },
    PHONE: {
        [languages.English]: "Phone number",
        [languages.Swedish]: "Telefonnummer"
    },
    COMPANY: {
        [languages.English]: "Company",
        [languages.Swedish]: "Företag"
    },
    TITLE: {
        [languages.English]: "Title",
        [languages.Swedish]: "Titel"
    },
    CREATE_USER: {
        [languages.English]: "Create user",
        [languages.Swedish]: "Skapa användare"
    },
    PASSWORD_ERROR:{
        [languages.English]: "Password must match",
        [languages.Swedish]: "Lösenord måste matcha"
    },
    EULA:{
        [languages.English]:"Accept EULA",
        [languages.Swedish]:"Godkänn användarvillkor"
    }
    
}

export const errors = {
    INVALID_MAIL: {
        [languages.English]: "Invalid email",
        [languages.Swedish]: "Ogiltig email"
    },
    MAIL_ALREADY_REGISTERED: {
        [languages.English]: "This email is already registered",
        [languages.Swedish]: "Denna email finns redan registrerad"
    },
    TOKEN_EXPIRED: {
        [languages.English]: "Registration has expired",
        [languages.Swedish]: "Registrering har gått ut"
    },
    UserNotFound: {
        [languages.English]: "User not found",
        [languages.Swedish]: "Användaren ej funnen"
    }
}

export const activityLogActions = {
    FileUpdate: {
        [languages.English]: "File updated",
        [languages.Swedish]: "Fil uppdaterad",
    },
    FileUpload: {
        [languages.English]: "File uploaded",
        [languages.Swedish]: "Fil uppladdad",
    },
    FileDelete: {
        [languages.English]: "File deleted",
        [languages.Swedish]: "Fil raderad",
    },
    FolderCreate: {
        [languages.English]: "Folder created",
        [languages.Swedish]: "Mapp skapad",
    },
    FolderDelete: {
        [languages.English]: "Folder deleted",
        [languages.Swedish]: "Mapp raderad",
    },
    FolderUpdate: {
        [languages.English]: "Folder updated",
        [languages.Swedish]: "Mapp uppdaterad",
    },
    MemberJoined: {
        [languages.English]: "Member invited",
        [languages.Swedish]: "Medlem inbjuden",
    },
    MemberLeft: {
        [languages.English]: "Member removed",
        [languages.Swedish]: "Medlem lämnat",
    },

}

export const FileDrop = {
    DROP_FILES: {
        [languages.English]: "Drop files",
        [languages.Swedish]: "Droppa filer",
    },
    OR: {
        [languages.English]: "or",
        [languages.Swedish]: "eller",
    },
    BROWSE: {
        [languages.English]: "BROWSE",
        [languages.Swedish]: "Bläddra",
    },
    NEXT: {
        [languages.English]: "Next",
        [languages.Swedish]: "Nästa",
    },
    CLOSE: {
        [languages.English]: "Close",
        [languages.Swedish]: "Stäng",
    },
    UPLOAD: {
        [languages.English]: "Upload",
        [languages.Swedish]: "Ladda upp",
    },
    GO_BACK: {
        [languages.English]: "Go back",
        [languages.Swedish]: "Tillbaka",
    },
    FILE_NAME: {
        [languages.English]: "File name",
        [languages.Swedish]: "Filnamn",
    },
    REVISION_NAME: {
        [languages.English]: "Rev",
        [languages.Swedish]: "Rev",
    },    
    CATEGORY: {
        [languages.English]: "Category",
        [languages.Swedish]: "Kategori",
    },
    DESCRIPTION: {
        [languages.English]: "Description",
        [languages.Swedish]: "Beskrivning",
    },
    STATUS: {
        [languages.English]: "Status",
        [languages.Swedish]: "Status",
    },
    SCALE: {
        [languages.English]: "Scale",
        [languages.Swedish]: "Skala",
    },
    CHOOSE_CAT: {
        [languages.English]: "Choose category",
        [languages.Swedish]: "Välj kategori",
    },
    CHOOSE_STATUS: {
        [languages.English]: "Choose status",
        [languages.Swedish]: "Välj status",
    },
    SEND_PM: {
        [languages.English]: "Send PM",
        [languages.Swedish]: "Skicka PM",
    },
    DATE: {
        [languages.English]: "Date",
        [languages.Swedish]: "Datum",
    }
}

export const Members = {
    MEMBERS_IN_PROJECT: {
        [languages.English]: "Members in project",
        [languages.Swedish]: "Deltagare i projektet",
    },
    FILTER_COMPANY: {
        [languages.English]: "Filter by company",
        [languages.Swedish]: "Filtrera på företag",
    },
    SEARCH_MEMBERS: {
        [languages.English]: "Search members",
        [languages.Swedish]: "Sök personer",
    },
    EDIT: {
        [languages.English]: "Edit",
        [languages.Swedish]: "Redigera",
    },
    REMOVE: {
        [languages.English]: "Remove",
        [languages.Swedish]: "Ta bort",
    },
    USERNAME: {
        [languages.English]: "Användarnamn",
        [languages.Swedish]: "Username",
    },
    PHONE: {
        [languages.English]: "Phone",
        [languages.Swedish]: "Telefon",
    },
    WORK_PHONE: {
        [languages.English]: "Phone work",
        [languages.Swedish]: "Telefon arbete",
    },    
    EMAIL: {
        [languages.English]: "Email",
        [languages.Swedish]: "Epost",
    },
    TEAM_ROLE: {
        [languages.English]: "Team role",
        [languages.Swedish]: "Roll",
    },
    COMPANY: {
        [languages.English]: "Company",
        [languages.Swedish]: "Företag",
    },
    PROJECT_OWNER: {
        [languages.English]: "Project owner",
        [languages.Swedish]: "Projektägare",
    },
    SEND_MESSAGE: {
        [languages.English]: "Send message",
        [languages.Swedish]: "Skicka meddelande",
    },
    MEMBER_LIST_XLSX: {
        [languages.English]: "Member list (.xlsx)",
        [languages.Swedish]: "Deltagarlista (.xlsx)",
    },
    INVITE_MEMBER: {
        [languages.English]: "Invite member",
        [languages.Swedish]: "Bjud in deltagare",
    },
    LOADING: {
        [languages.English]: "Loading",
        [languages.Swedish]: "Laddar",
    },
    WRITE_EMAIL: {
        [languages.English]: "Write email",
        [languages.Swedish]: "Skriv epost",
    },
    EDITING: {
        [languages.English]: "Editing",
        [languages.Swedish]: "Redigerar",
    },
    ADD_MEMBER: {
        [languages.English]: "Add member",
        [languages.Swedish]: "Bjud in deltagare",
    },
    ROLES: {
        [languages.English]: "Roles",
        [languages.Swedish]: "Roller",
    },
    CHOOSE_ROLE: {
        [languages.English]: "Choose role",
        [languages.Swedish]: "Välj roll",
    },
    ADD: {
        [languages.English]: "Add",
        [languages.Swedish]: "Lägg till",
    },
    UPDATE: {
        [languages.English]: "Save",
        [languages.Swedish]: "Spara",
    },
    SEND_INVITATION: {
        [languages.English]: "Send invitation",
        [languages.Swedish]: "Skicka inbjudan",
    },
    CLOSE: FileDrop.CLOSE,
    CHOOSE: {
        [languages.English]: "Choose",
        [languages.Swedish]: "Välj",
    }
}


export const Messages = {
    MESSAGES: {
        [languages.English]: "Messages",
        [languages.Swedish]: "Meddelanden",
    },
    MESSAGE: {
        [languages.English]: "Message",
        [languages.Swedish]: "Meddelande",
    },
    CREATE_MESSAGE: {
        [languages.English]: "Create new message",
        [languages.Swedish]: "Skapa nytt meddelande",
    },
    RECIPIENT: {
        [languages.English]: "Recipient",
        [languages.Swedish]: "Mottagare",
    },
    RECIPIENT_REQUIRED: {
        [languages.English]: "Recipient is required",
        [languages.Swedish]: "Måste ha en mottagare",
    },
    HEADING: {
        [languages.English]: "Heading",
        [languages.Swedish]: "Rubrik",
    },
    SEND: {
        [languages.English]: "Send",
        [languages.Swedish]: "Skicka",
    },
    CANCEL: {
        [languages.English]: "Cancel",
        [languages.Swedish]: "Avbryt",
    },
    GO_BACK: FileDrop.GO_BACK
}


export const MyUser = {
    LOADING: Members.LOADING,
    ARE_YOU_SURE_YOU_WANT_TO_LEAVE: {
        [languages.English]: 'You have made changes which are unsaved, if you continue they will disappear. Do you want to continue?',
        [languages.Swedish]: 'Du har gjort ändringar som inte är sparade, fortsätter du försvinner dessa ändringar. Vill du fortsätta?'
    },
    NO_COMPANIES: {
        [languages.English]: "No companies to select",
        [languages.Swedish]: "Finns inga företag att välja",
    },
    CREATE: {
        [languages.English]: "Create",
        [languages.Swedish]: "Skapa",
    },
    PROFILE: {
        [languages.English]: "Profile",
        [languages.Swedish]: "Profil",
    },
    SETTINGS: {
        [languages.English]: "Settings",
        [languages.Swedish]: "Inställningar",
    },
    FIRSTNAME: loginForm.FIRSTNAME,
    LASTNAME: loginForm.LASTNAME,
    EMAIL: loginForm.EMAIL,
    PASSWORD: loginForm.PASSWORD,
    CURRENT_PASSWORD:{
        [languages.English]: "Current password",
        [languages.Swedish]: "Nuvarande lösenord",
    },
    NEW_PASSWORD: {
        [languages.English]: "New password",
        [languages.Swedish]: "Nytt lösenord",
    },
    CONFIRM_NEW_PASSWORD: {
        [languages.English]: "Confirm new password",
        [languages.Swedish]: "Bekräfta nytt lösenord",
    },
    PASSWORD_MATCH_MESSAGE: {
        [languages.English]: "Field must match with 'New password'",
        [languages.Swedish]: "Fältet måste matcha med 'Nytt lösenord'",
    },
    WORKPHONE: {
        [languages.English]: "Work phone",
        [languages.Swedish]: "Telefon arbete",
    },
    MOBILEPHONE: {
        [languages.English]: "Mobile phone",
        [languages.Swedish]: "Telefon mobil",
    },
    TITLE: loginForm.TITLE,
    CHOOSE_IMAGE: {
        [languages.English]: "Choose image",
        [languages.Swedish]: "Välj bild",
    },
    CREATE_COMPANY_TEXT: {
        [languages.English]: "Select or create company",
        [languages.Swedish]: "Välj eller skapa företag",
    },
    COMPANY: loginForm.COMPANY,
    LANGUAGE: {
        [languages.English]: "Language",
        [languages.Swedish]: "Språk",
    },
    UPDATE: Members.UPDATE,
    PROFILE_IMAGE: {
        [languages.English]: "Profile image",
        [languages.Swedish]: "Profilbild",
    },
    UPLOAD_IMAGE_HEADING: {
        [languages.English]: "Upload profile image",
        [languages.Swedish]: "Ladda upp profilbild",
    },
    UPLOAD_IMAGE_RULES: {
        [languages.English]: "Use square images for best results",
        [languages.Swedish]: "Använd fyrkantiga bilder för att inte få konstiga skalningseffekter på din profilbild",
    },
    DRAG_AND_DROP_IMAGE: {
        [languages.English]: "Drag and drop image file here",
        [languages.Swedish]: "Dra och släpp bildfil här",
    },
    OR_CLICK: {
        [languages.English]: "or click for file handler",
        [languages.Swedish]: "eller klicka för filhanterare",
    },
    CANCEL: Messages.CANCEL,
    EMAIL_FROM_NOTI: {
        [languages.English]: "Email from notification",
        [languages.Swedish]: "Epost vid notifiering",
    },
    NOTI_PER_PROJECT: {
        [languages.English]: "Notifications per project",
        [languages.Swedish]: "Notifikation per projekt",
    },
    SAVE: {
        [languages.English]: "Save",
        [languages.Swedish]: "Spara",
    },
    NOTI_INFO: {
        [languages.English]: "A checked folder will generate a notification from project events",
        [languages.Swedish]: "En mapp med en bock kommer generera en notifiering vid projekthändelse",
    },
    SETTINGS_HAVE_BEEN_SAVED: {
        [languages.English]: 'Settings have been saved',
        [languages.Swedish]: 'Inställningarna är sparade'
    }

}

export const Notifications = {
    NOTIFICATIONS: {
        [languages.English]: "Notifications",
        [languages.Swedish]: "Notifikationer",
    },
    SHOW_ALL: {
        [languages.English]: "Show all",
        [languages.Swedish]: "Visa alla",
    },
    VISIT: {
        [languages.English]: "Mark as read",
        [languages.Swedish]: "Markera som läst",
    }
}

export const ActivityLog = {
    ACTIVITYACTOR: {
        [languages.English]: "Actor",
        [languages.Swedish]: "Aktör",
    },
    ACTIVITY: {
        [languages.English]: "Activity",
        [languages.Swedish]: "Aktivitet",
    },
    NAME: projectDictionary.NAME,
    DATEFROM: {
        [languages.English]: "From",
        [languages.Swedish]: "Från",
    },
    DATETO: {
        [languages.English]: "To",
        [languages.Swedish]: "Till",
    },
    SEARCH: {
        [languages.English]: "Search",
        [languages.Swedish]: "Sök",
    },
    HEAD_FOLDERS: {
        [languages.English]: "Head folders",
        [languages.Swedish]: "Huvudmappar",
    },
    SUB_FOLDERS: {
        [languages.English]: "Sub folders",
        [languages.Swedish]: "Undermappar",
    }

}

export const ÄTA = {
    CREATE_CA: {
        [languages.English]: "Create new variation",
        [languages.Swedish]: "Skapa ny ÄTA",
    },
    NUMBER: {
        [languages.English]: "Number",
        [languages.Swedish]: "Nummer",
    },
    HEADING: Messages.HEADING,
    PRICE: {
        [languages.English]: "Price",
        [languages.Swedish]: "Pris",
    },
    DESCRIPTION: projectDictionary.DESCRIPTION,
    APPEND_FILES: {
        [languages.English]: "Append files",
        [languages.Swedish]: "Bifoga filer",
    },
    APPEND_INFO: {
        [languages.English]: "You can add max 5 files",
        [languages.Swedish]: "Du kan lägga till max 5 filer",
    },
    APPEND_INFO_SIZE: {
        [languages.English]: "(10MB max per file)",
        [languages.Swedish]: "(10MB max per fil)",
    },
    EDIT_CA: {
        [languages.English]: "Update CA",
        [languages.Swedish]: "Uppdatera ÄTA",
    }
}

export const PM = {
    FROM: {
        [languages.English]: "From",
        [languages.Swedish]: "Från",
    },
    HEADING: Messages.HEADING,
    RECIPIENT: Messages.RECIPIENT,
    DATE: projectDictionary.DATE,
    SENT: {
        [languages.English]: "Sent",
        [languages.Swedish]: "Skickat",
    },
    MESSAGE: Messages.MESSAGE,
    CLOSE: FileDrop.CLOSE,
    UPLOADING: {
        [languages.English]: "Uploading",
        [languages.Swedish]: "Uppladdning",
    }
}

export const QA = {
    CREATE_NEW: {
        [languages.English]: "Create new",
        [languages.Swedish]: "Skapa ny",
    },
    ACTOR: {
        [languages.English]: "Asking actor",
        [languages.Swedish]: "Frågande aktör",
    },
    PUBLIC: {
        [languages.English]: "Answering actor",
        [languages.Swedish]: "Svarande aktör",
    },
    HEADING: Messages.HEADING,
    DATE: projectDictionary.DATE,
    // LAST_ANSWER: {
    //     [languages.English]: "Answer by",
    //     [languages.Swedish]: "Svara senast",
    // },
    NO_QA: {
        [languages.English]: "No question / answer created",
        [languages.Swedish]: "Inga frågor / svar skapade",
    },
    OVERVIEW: {
        [languages.English]: "Overview",
        [languages.Swedish]: "Översikt",
    },
    ADMIN_WAITING:{
        [languages.English]: "Waiting for admin approval",
        [languages.Swedish]: "Väntar på godkännande av admin",
    },
    HIDE_CONVERSATION:{
        [languages.English]: "Hide conversation",
        [languages.Swedish]: "Dölj konversation",
    },
    SHOW_CONVERSATION:{
        [languages.English]: "Show conversation",
        [languages.Swedish]: "Visa konversation",
    },
    MARK_ANSWER:{
        [languages.English]: "Mark as answer",
        [languages.Swedish]: "Markera som svar",
    },
    LAST_ANSWER:{
        [languages.English]: "Last answer",
        [languages.Swedish]: "Senaste svar",
    },
    CREATE_ÄTA:{
        [languages.English]: "Create CA",
        [languages.Swedish]: "Medför ÄTA",
    },
    SAVE:{
        [languages.English]: "Save",
        [languages.Swedish]: "Spara",
    },
    REPLACE_ANSWER:{
        [languages.English]: "Replace answer",
        [languages.Swedish]: "Ersätt svar",
    },
    ACCEPT:{
        [languages.English]: "Acceps",
        [languages.Swedish]: "Godkänn",
    },
    REJECT:{
        [languages.English]: "Reject",
        [languages.Swedish]: "Godkänn ej",
    },
    SEND:Messages.SEND,
    WRITE_ANSWER:{
        [languages.English]: "Write answer",
        [languages.Swedish]: "Skriv svar",
    }
    
}

export const ProjectEdit = {
    ACTIVE_MAPS: {
        [languages.English]: "Active folders",
        [languages.Swedish]: "Aktiva mappar",
    },
    CREATE_CAT: {
        [languages.English]: "Create new head folder",
        [languages.Swedish]: "Skapa ny huvudmapp",
    },
    NEW_HEAD_CAT: {
        [languages.English]: "New head folder",
        [languages.Swedish]: "Ny huvudmapp",
    },
    HEAD_CAT_NAME: {
        [languages.English]: "Name",
        [languages.Swedish]: "Namn",
    },
    CONTAINS_SUBFOLDERS: {
        [languages.English]: "Contains sub folders",
        [languages.Swedish]: "Innehåller undermappar",
    },
    CANCEL: Messages.CANCEL,
    CREATE: MyUser.CREATE,
    NEW_SUBFOLDER:{
        [languages.English]: "New sub folder",
        [languages.Swedish]: "Ny undermapp",
    },
    NAME_OF_SUBFOLDER:{
        [languages.English]: "Name of sub folder",
        [languages.Swedish]: "Namn på undermapp",
    },
    CREATE_NEW_SUBFOLDER:{
        [languages.English]: "Create new sub folder",
        [languages.Swedish]: "Skapa ny undermapp",
    },
    UPDATE:Members.UPDATE,
    ARCHIVE_PROJECT:{
        [languages.English]: "Archive project",
        [languages.Swedish]: "Arkivera projekt",
    },
    RESTORE_PROJECT:{
        [languages.English]: "Restore project",
        [languages.Swedish]: "Återställ projekt",
    },
    ARCHIVE_PROJECT_HEADING:{
        [languages.English]: "Archive",
        [languages.Swedish]: "Arkivering",
    },
    RESTORE_PROJECT_HEADING:{
        [languages.English]: "Restore",
        [languages.Swedish]: "Återställning",
    },
    ARCHIVE_PROJECT_INFO:{
        [languages.English]: "Are you sure you want to archive the project? Only admins will be able to access the project. Uploaded files can be downloaded but not changed.",
        [languages.Swedish]: "Är du säker på att du vill arkivera projektet? Enbart administratörer kommer ha fortsatt åtkomst till projektet. Uppladdade filer kan laddas hem men inte ändras.",
    },
    RESTORE_PROJECT_INFO:{
        [languages.English]: "Are you sure you want to restore project",
        [languages.Swedish]: "Är du säker på att du vill återställa projekt",
    },
    CREATE_PROJECT: projectCreateFormDictionary.CREATE_PROJECT,
    UPLOAD:FileDrop.UPLOAD,
    START_DATE:{
        [languages.English]: "Start date",
        [languages.Swedish]: "Startdatum",
    },
    ESTIMATED_END:{
        [languages.English]: "Estimated finish",
        [languages.Swedish]: "Estimerat avslut",
    },
    ACCESS:{
        [languages.English]: "Access",
        [languages.Swedish]: "Tillträde",
    },
    SURFACE:{
        [languages.English]: "Surface",
        [languages.Swedish]: "Yta",
    },
    SELL_START:{
        [languages.English]: "Sell start",
        [languages.Swedish]: "Säljstart",
    },
    ESTATE_COUNT:{
        [languages.English]: "Number of estates",
        [languages.Swedish]: "Antal bostäder",
    },
    AMOUNT:{
        [languages.English]: "Quantity",
        [languages.Swedish]: "Antal",
    },
    CHOOSE_IMAGE:MyUser.CHOOSE_IMAGE,
    PROJECT_IMAGE:{
        [languages.English]: "Project image",
        [languages.Swedish]: "Projektbild",
    },
    COMMON_INFO:{
        [languages.English]: "Common info",
        [languages.Swedish]: "Övrig info",
    },
    SITE_MANAGER:{
        [languages.English]: "Site manager",
        [languages.Swedish]: "Platschef",
    },
    SUPERVISOR:{
        [languages.English]: "Supervisor",
        [languages.Swedish]: "Arbetsledare",
    },
    CONSTRUCTION_MANAGER:{
        [languages.English]: "Construction manager",
        [languages.Swedish]: "Entreprenadschef",
    }
}