import * as messagesTypes from './actionTypes';
import Immutable from 'seamless-immutable'

const initState = Immutable({
    conversations: [],
    conversation: undefined,
    isRequesting: false,
    error: undefined
})

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case messagesTypes.MESSAGES_IS_REQUESTING:
            return state.merge({
                isRequesting: true
            })
        case messagesTypes.GET_CONVERSATION_LIST_SUCCESS:
            return state.merge({
                conversations: action.payload,
                isRequesting: false
            })
        case messagesTypes.GET_CONVERSATION_SUCCESS:
            return state.merge({
                conversation: action.payload,
                isRequesting: false
            })
        case messagesTypes.GET_CONVERSATION_FAILED:
        case messagesTypes.GET_CONVERSATION_LIST_FAILED:
            return state.merge({
                isRequesting: false,
                error: action.payload
            })
        case messagesTypes.CREATE_CONVERSATION_SUCCESS:
            return state.merge({
                isRequesting:false
            })
        default:
            return state;
    }
}