//handle if we shall fetch new message and notification statuses
export const PAUSE_FETCHSTATUS = "user.PAUSE_FETCHSTATUS";
export const RESUME_FETCHSTATUS = "user.RESUME_FETCHSTATUS";

export const LOGIN_PENDING = "user.LOGIN_PENDING";
export const LOGIN_SUCCESS = "user.LOGIN_SUCCESS";
export const LOGIN_FAILED = "user.LOGIN_FAILED";

// Logout is only a matter of changing browser storage and erasing logout from store
export const LOGOUT = "user.LOGOUT";

export const GET_USERS_PROJECT_PENDING = "user.GET_USERS_PROJECT_PENDING"
export const GET_USERS_PROJECT_SUCCESS = "user.GET_USERS_PROJECT_SUCCESS"
export const GET_USERS_PROJECT_FAILED = "user.GET_USERS_PROJECT_FAILED"

export const GET_NOTIFICATIONS_PENDING = "user.GET_NOTIFICATIONS_PENDING"
export const GET_NOTIFICATIONS_SUCCESS = "user.GET_NOTIFICATIONS_SUCCESS"
export const GET_NOTIFICATIONS_FAILED = "user.GET_NOTIFICATIONS_FAILED"

export const CREATE_USER_PENDING = "user.CREATE_USER_PENDING"
export const CREATE_USER_SUCCESS = "user.CREATE_USER_SUCCESS"
export const CREATE_USER_ERROR = "user.CREATE_USER_ERROR"

export const CLEAR_ERROR = "user.CLEAR_ERROR"

export const UPDATE_USER_PENDING = "user.UPDATE_USER_PENDING";
export const UPDATE_USER_SUCCESS = "user.UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "user.UPDATE_USER_FAILED";


// If someone feel like it, refactor and use this for all requests to make things simple
export const USER_REQUESTING = 'user.USER_REQUESTING'

export const INVITATION_SUCCESS = 'user.INVITATION_SUCCESS'
export const INVITATION_FAILED = 'user.INVITATION_FAILED'

export const PRIVILEGE_UPDATE_SUCCESS = 'user.PRIVILEGE_UPDATE_SUCCESS'
export const PRIVILEGE_UPDATE_FAILED = 'user.PRIVILEGE_UPDATE_FAILED'

export const SENT_FORGOT_PASSWORD_SUCCESS = 'user.SENT_FORGOT_PASSWORD_SUCCESS'
export const SENT_FORGOT_PASSWORD_FAILED = 'user.SENT_FORGOT_PASSWORD_FAILED'

export const UPDATE_PASSWORD_FAILED = 'user.UPDATE_PASSWORD_FAILED'

export const RESET_MEMBERS = 'user.RESET_MEMBERS'